@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-light";
  src: url("../fonts/Lato-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-Bold";
  src: url("../fonts/Lato-Bold.ttf") format("truetype");
}

body {
  background: white;
}

section {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
a {
  line-height: 1;
  word-break: break-word;
  word-wrap: break-word;
  font-weight: 700;
}

a {
  cursor: pointer;
  text-decoration: none;
}

/* fonts */
.display-1 {
  font-family: Lato;
  font-size: 2rem;
}

/* end fonts */
/* btn config */
.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  border-color: transparent;
}

/* UP BTN */
.btn-up {
  background-color: #b4dfdf;
  bottom: 95px;
  box-shadow: 0 2px 10px lightslategray;
  right: 15px;
  text-align: center;
  width: 30px;
  height: 30px;
  position: fixed;
  z-index: 2147483647;
  cursor: pointer;
  border-radius: 30px;
  transition: all 0s ease;
  opacity: 1;
  transition: opacity 0.7s ease;
}

.btn-up-hide {
  opacity: 0;
  transition: opacity 0.7s ease;
}

.btn-up:hover {
  background: rgba(17, 30, 53, 0.8) none repeat scroll 0 0;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.btn-up i {
  color: #ffffff;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}

@media (max-width: 991px) {
  .btn-up {
    bottom: 490px;
    right: 12px;
  }
}

@media (max-width: 768px) {
  .btn-up {
    right: 12px;
    bottom: 490px;
    width: 30px;
    height: 30px;
  }

  .btn-up i {
    font-size: 20px;
    line-height: 30px;
  }
}

@media (max-width: 576px) {}

/* menu */
.menu .desktop {
  padding: 0.5rem 3rem;
  transition: all 0.3s;
  border-bottom: 1px solid transparent;
  background: rgba(82, 209, 205, 0.8);
  backdrop-filter: blur(3px);
  height: 5.4em;
  border-bottom: 1px solid #52d1cd;
  z-index: 1000;
  width: 100%;
  transition: opacity 0.5s;
  opacity: 1;
}

.menu .mobile {
  padding: 0.5rem 0.5rem;
  transition: all 0.3s;
  border-bottom: 1px solid transparent;
  background: rgba(82, 209, 205, 0.8);
  backdrop-filter: blur(3px);
  border-bottom: 1px solid #52d1cd;
  transition: all 0.3s;
  z-index: 1000;
  width: 100%;
  transition: opacity 0.5s;
  opacity: 1;
}

.menu .navbar.opened {
  background: rgba(17, 30, 53, 0.8);
}

/* .menu .navbar-brand {
  background-color: #000000;
} */
.menu img {
  height: 5rem;
}

.menu li {
  padding: 0 0.3em !important;
  margin: 0.667em 0.6em !important;
}

.menu a {
  font-family: Lato;
  font-weight: 400;
  transition: color 0.2s ease-in-out;
  font-size: 1.1rem;
  line-height: 1.5;
  text-align: center;
  text-transform: capitalize;
  color: #4f6c6b;
}

.menu .nav-link:focus,
.nav-link:hover {
  color: #ffffff;
}

.menu .navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #ffffff;
}

.menu .icon {
  flex-wrap: nowrap;
  display: flex;
  justify-content: center;
  padding-left: 3rem;
  padding-right: 1rem;
  padding-top: 0.3rem;
  text-align: center;
}

.menu .icon a {
  transition: color 0.2s ease-in-out;
  padding-right: 1.5rem;
}

.menu .icon i {
  transition: color 0.2s ease-in-out;
  font-size: 1.3rem;
  line-height: 1.4;
  color: #52d1cd;
  background-color: white;
  border-radius: 50%;
  width: 1.8rem;
  height: 1.8rem;
}

/*mobile*/
.menu .navbar-toggler {
  border: none;
  position: relative;
}

.menu .navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}

.menu .line-btn span {
  position: absolute;
  right: 0;
  width: 30px;
  height: 2px;
  border-right: 5px;
  background-color: currentColor;
  color: #ffffff;
}

.menu .line-btn span:nth-child(1) {
  top: 0px;
  transition: all 0.15s;
}

.menu .line-btn span:nth-child(2) {
  top: 8px;
  transition: all 0.15s;
}

.menu .line-btn span:nth-child(3) {
  top: 16px;
  transition: all 0.15s;
}

.menu .line-btn-x span {
  position: absolute;
  right: 0;
  width: 30px;
  height: 2px;
  border-right: 5px;
  background-color: currentColor;
  color: #ffffff;
}

.menu .line-btn-x span:nth-child(1) {
  top: 8px;
  transform: rotate(45deg);
  transition: all 0.25s;
}

.menu .line-btn-x span:nth-child(2) {
  top: 8px;
  transition: all 0.25s;
  transform: rotate(-45deg);
}

.menu .social {}

@media (max-width: 991px) {
  .menu .desktop {
    padding: 0.5rem 1.5rem;
    transition: all 0.3s;
    border-bottom: 1px solid transparent;
    background: rgba(82, 209, 205, 0.8);
    backdrop-filter: blur(3px);
    height: 5.5em;
    border-bottom: 1px solid #52d1cd;
    z-index: 1000;
    width: 100%;
  }

  .menu li {
    padding: 0 0.3em !important;
    margin: 0.2em 0em !important;
  }

  .menu a {
    font-family: Lato;
    font-weight: 500;
    transition: color 0.2s ease-in-out;
    font-size: 1rem;
    line-height: 1.5;
  }

  .menu img {
    height: 4rem;
  }
}

@media (max-width: 576px) {
  .menu .desktop {
    padding: 0.5rem 0.5rem;
    height: 6em;
    margin-top: -10px;
  }

  .menu .navbar-brand {
    width: auto;
  }

  .menu img {
    height: 4.5em;
    width: auto;
    margin-top: 6px;
  }
}

/* end menu */
/* FOOTER */
footer {
  height: 30;
  width: 100%;
  background-image: url("../img/footer/background_footer.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #f1f7f7;
}

.footer .footer-container {
  padding-top: 0.1rem;
  padding-bottom: 4rem;
}

.footer .colum {
  padding: 5.5rem;
}

.list-inline,
.list-unstyled {
  width: 15rem;
}

.footer ul li {
  padding: 1px;
  text-transform: capitalize;
  font-family: Lato;
  text-align: end;
  margin-right: 0%;
  font-size: 1rem;
  color: #606060;
}

.footer ul li a {
  color: #606060 !important;
  font-weight: 400;
  font-family: Lato;
  text-transform: capitalize;
  font-size: 1rem;
}

.footer .contact ul li {
  padding: 1px;
  text-transform: none !important;
  font-family: Lato;
  text-align: start;
  font-size: 1rem;
  margin-right: 0%;
  color: #606060;
}

.footer .icon-text {
  display: flex;
  align-items: flex-start;
}

.footer .contact i {
  margin-right: 1rem;
}

.footer .contact i {
  color: #347674;
}

.footer .dashed-border-none {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin: 0 auto;
  margin-top: 1.5rem;
}

.footer .dashed-border {
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 0.8px solid #347674;
  height: 100px;
  margin: 0 auto;
  margin-top: 1.5rem;
}

.footer .dashed-border img {
  width: auto;
  height: 10rem;
}

.footer .copyrigth {
  background-color: #8d8c84;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4rem;
  font-family: Lato;
}

.footer .copyrigth-content {
  flex: 1;
}

.footer .copyrigth-text {
  margin: 0;
  color: white;
  font-family: Lato-light;
  font-size: 0.9rem;
  text-align: center;
}

.footer .copyrigth-image img {
  width: 8rem;
}

.footer .icon-footer {
  display: flex;
  justify-content: right;
  align-items: end;
  color: white;
  margin-right: 2rem;
  padding-top: 1rem;
}

.footer .icon-footer i {
  transition: color 0.2s ease-in-out;
  font-size: 1.3rem;
  line-height: 1.4;
  color: #ffffff;
  background-color: #347674;
  border-radius: 50%;
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.8rem;
  text-align: center;
}

@media (max-width: 767px) {
  footer {
    background-image: url("../img/footer/footer-mobile.png");
  }

  .footer .copyrigth {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 1rem;
  }

  .footer .copyrigth-content {
    margin-bottom: 10px;
  }

  .footer .copyrigth-image img {
    width: auto;
    height: 2rem;
  }
}

@media (max-width: 992px) {
  footer {
    height: auto;
  }

  .footer-container {
    padding-top: 3rem;
  }

  .footer .colum {
    padding: 0rem !important;
  }

  .footer ul {
    text-align: center;
  }

  .footer ul li {
    text-align: center;
    margin-right: 0px;
    font-size: 1rem;
    padding-bottom: 1rem;
  }

  .footer ul li a {
    text-align: center;
    margin-right: 0px;
    font-size: 1rem;
    padding-bottom: 1rem;
  }

  .footer .list-inline,
  .list-unstyled {
    width: 20rem;
    text-align: center;
  }

  .footer .contact {
    margin-left: 0rem;
    margin-right: 0rem;
  }

  .footer .contact ul li {
    font-size: 1rem;
    width: 100%;
    text-align: center;
  }

  .footer .p-1 {
    padding-top: 16rem !important;
  }

  .footer .p-2 {
    padding-top: 7rem !important;
  }

  .footer .p-3 {
    padding-top: 11rem !important;
  }

  .footer .p-5 {
    padding-bottom: 1px !important;
  }

  .footer p {
    text-align: center;
  }

  .footer .colum {
    padding: 1rem;
  }

  .footer .dashed-border-none {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin: 0 auto;
    margin-top: 1.5rem;
  }

  .footer .dashed-border {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: transparent;
    height: 100px;
    margin: 0 auto;
    margin-top: 0px !important;
  }

  .footer .dashed-border img {
    width: auto;
    height: 15rem;
  }

  .footer .copyrigth {
    margin-top: 3rem !important;
  }

  .footer .icon-text {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-bottom: 1.3rem;
  }

  .footer .icon-text i {
    margin-right: 0;
    margin-bottom: 10px;
    font-size: 1.5rem;
  }

  .footer .icon-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0px;
    padding-top: 1rem;
    padding-bottom: 2rem;
  }

  .footer .icon-footer i {
    font-size: 1.5rem;
    line-height: 1.4;
    width: 2rem;
    height: 2rem;
    margin-right: 0.8rem;
  }
}

@media (max-width: 768px) {
  .footer .footer-container {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .footer ul li {
    font-size: 1rem;
  }

  .footer .contact ul li {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .footer .footer-container {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

/* END FOOTER */
/*HOME*/
.home-banner {
  /*  min-height: 100vh; */
  display: flex;
  align-items: flex-start;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #fafafa;
  overflow: hidden;
  position: relative;
}

.home-banner .carousel {
  width: 100%;
  height: auto;
}

.home-banner .carousel-item {
  min-width: 100%;
  max-height: 100%;
  position: relative;
  overflow: hidden;
}

.home-banner img {
  width: 100%;
  max-height: 50rem;
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}

.banner-caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 2rem;
}

.banner-caption h5,
.banner-caption p {
  margin: 0;
}

@media (max-width: 1600px) {
  .home-banner img {
    max-height: 100vh;
  }
}

@media (max-width: 991px) {

  .home-banner .carousel,
  .home-banner img {
    height: auto;
    max-height: none;
  }
}

.home-banner .carousel-control {
  top: 50%;
  width: 50px;
  height: 50px;
  margin-top: -1.5rem;
  background-color: transparent;
  border: 2px solid #9b8c79;
  border-radius: 50%;
  transition: all 0.3s;
  right: 0;
  margin-right: 2.5rem;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
}

.home-banner .carousel-control span {
  font-size: 25px;
  color: #9b8c79;
}

.home-banner .carousel-control-left {
  top: 50%;
  width: 50px;
  height: 50px;
  margin-top: -1.5rem;
  background-color: transparent;
  border: 2px solid #9b8c79;
  border-radius: 50%;
  transition: all 0.3s;
  right: 0;
  margin-left: 2.5rem;
}

.home-banner .carousel-control:hover {
  background: #ffffff;
  color: #fff;
  opacity: 1;
}

.home-banner .carousel-indicators {
  position: absolute;
  bottom: 0;
  margin-bottom: 1.5rem !important;
}

.home-banner .carousel-indicators button {
  max-width: 10px;
  height: 10px !important;
  width: 10px !important;
  max-height: 10px;
  margin: 3px;
  margin-right: 10px;
  background-color: transparent !important;
  border: 2px solid #52d1cd !important;
  border-radius: 50%;
  opacity: 0.5;
  transition: all 0.3s;
}

.home-banner .carousel-indicators .active {
  opacity: 1;
  background-color: #52d1cd !important;
}

.home-banner .carousel-caption.btn {
  text-align: left !important;
  top: 40%;
  left: 10%;
  right: 10%;
}

.home-banner .carousel-caption {
  text-align: left !important;
  top: 35%;
  left: 10%;
  right: 10%;
}

.home-banner .force-botton {
  bottom: 4em !important;
}

.home-banner .carousel-caption h5 {
  color: #347674;
  font-size: 3rem;
  font-family: Lato-Bold;
}

.home-banner .carousel-caption p {
  text-align: left;
  color: #606060;
  font-family: Lato;
  width: 50% !important;
  font-size: 1.5rem;
  line-height: 25px;
}

.home-banner .carousel-caption .caption2 {
  text-align: left;
  color: #ffffff;
  font-family: Lato;
  width: 80% !important;
  width: 38%;
}

.home-banner .carousel-caption .bold {
  font-weight: 900;
  font-size: 1.8em;
}

.home-banner .carousel-caption .slide2 img {
  max-height: 600px;
  object-fit: contain;
}

.home-banner button {
  border-radius: 7px;
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.75rem;
  padding: 0.3em;
  width: 10rem;
}

.home-banner .btn-banner {
  background-color: #a29885;
  color: #ffffff !important;
  font-size: 1.1rem;
  font-family: Lato-Bold;
  padding-left: 2rem;
  padding-right: 2rem;
}

.home-banner .btn-banner:active {
  background-color: #fafafa;
  color: #258ff3;
}

.home-banner .btn-banner i {
  font-size: 1rem;
  font-weight: 600;
  margin-right: 0.2rem;
}

.whatsapp-fixed {
  position: fixed;
  bottom: 44%;
  right: 0.5rem;
  z-index: 2147483647;
  transition: all 0.9s ease-in-out;
}

.whatsapp-fixed img {
  right: 3rem;
  width: 5rem;
  z-index: 10;
  margin-right: 0rem;
  align-items: end;
  text-align: right;
  margin-top: -1.7rem;
  transition: all 0.9s ease-in-out;
}

.whatsapp {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 2147483647;
  transition: all 0.9s ease-in-out;
}

.whatsapp img {
  right: 3rem;
  width: 5rem;
  z-index: 10;
  margin-right: 0rem;
  align-items: end;
  text-align: right;
  margin-top: -1.7rem;
  transition: all 0.9s ease-in-out;
}

.show {
  opacity: 1;
  /* Mostrar el elemento */
}

@media (max-width: 991px) {
  .whatsapp-fixed {
    right: 4rem;
    bottom: 43%;
  }

  .whatsapp-fixed img {
    width: 4rem;
    margin-right: 0rem;
    margin-top: 0rem;
  }

  .whatsapp {
    position: fixed;
    bottom: 10%;
    right: -1rem;
    z-index: 2147483647;
  }

  .whatsapp img {
    right: 2rem;
    width: 4rem;
    position: absolute;
    z-index: 10;
    margin-right: 0rem;
    margin-top: -1.7rem;
  }
}

@media (max-width: 768px) {
  .whatsapp-fixed {
    right: 15%;
    bottom: 475px;
  }
}

@media (max-width: 1200px) {
  .home-banner .carousel-caption {
    text-align: left !important;
    bottom: 16%;
  }

  .home-banner .carousel-caption h5 {
    color: #347674;
    font-weight: bold;
    font-size: 2.5em;
  }

  .home-banner .carousel-caption p {
    text-align: left;
    color: #606160 !important;
    font-family: Lato;
    width: 30%;
    font-size: 1em;
    width: 38%;
  }

  .home-banner .carousel-caption .caption2 {
    text-align: left;
    color: #ffffff;
    font-family: Lato;
    width: 80% !important;
    font-size: 1em;
    width: 38%;
  }
}

@media (max-width: 992px) {
  .full-screen {
    right: 5% !important;
    left: 5% !important;
  }

  .home-banner .carousel-caption {
    text-align: left !important;
    bottom: 0;
    top: 70%;
  }

  .home-banner .carousel-caption.content-btn {
    text-align: left !important;
    bottom: 0;
    top: 60%;
  }

  .home-banner .carousel-caption h5 {
    font-weight: bold;
    font-size: 2.5em;
    text-align: center;
  }

  .home-banner .carousel-caption p {
    text-align: left;
    font-family: Lato;
    width: 100% !important;
    font-size: 1em;
    width: 100%;
    text-align: center;
  }

  .home-banner .carousel-caption .caption2 {
    text-align: left;
    font-family: Lato;
    width: 100% !important;
    font-size: 1em;
    text-align: center;
  }

  .home-banner .carousel-caption .center-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
  }

  .home-banner .carousel,
  .home-banner img {
    height: 42rem;
    max-height: none;
  }
}

@media (max-width: 768px) {
  .home-banner .carousel-caption {
    text-align: left !important;
    bottom: 0;
    top: 65%;
  }

  .home-banner .carousel-caption.content-btn {
    text-align: left !important;
    bottom: 0;
    top: 60%;
  }

  .home-banner .carousel-caption h5 {
    font-weight: bold;
    font-size: 1.5em;
  }

  .home-banner.carousel-caption p {
    text-align: left;
    font-family: Lato;
    width: 40% !important;
    font-size: 1rem;
  }

  .home-banner .carousel-caption .caption2 {
    text-align: left;
    color: #ffffff;
    font-family: Lato;
    width: 100% !important;
    font-size: 1rem;
    text-align: center;
  }

  .home-banner .carousel-control {
    margin-top: 4rem;
    max-width: 35px;
    height: 35px !important;
    width: 35px !important;
    max-height: 35px;
  }

  .home-banner .carousel-control-left {
    margin-top: 4rem;
  }

  .home-banner .carousel-caption h5 {
    font-size: 2rem;
    margin-left: 0px;
    text-align: center;
  }

  .home-banner .carousel-caption p {
    font-size: 1rem;
    margin-left: 0px;
    text-align: center;
    width: 100% !important;
    line-height: 19px;
  }

  .home-banner .btn-banner {
    color: #ffffff;
    font-size: 0.80rem;
  }

  .home-banner .carousel-caption .center-btn button {
    font-size: 0.9rem;
  }

  .home-banner .carousel-caption .center-btn i {
    margin-right: 0.4rem;
  }
}

@media (max-width: 576px) {
  .home-banner .home-banner .carousel-item {
    height: 50vh;
    position: relative;
    overflow: hidden;
  }

  .home-banner .carousel-caption {
    text-align: left !important;
    bottom: 0;
    top: 58%;
  }

  .home-banner .carousel-caption.content-btn {
    text-align: left !important;
    bottom: 0;
    top: 45%;
  }

  .home-banner .carousel,
  .home-banner img {
    height: 35rem;
    max-height: none;
  }

  .home-banner .carousel-caption h5 {
    font-size: 2em;
  }

  .home-banner .carousel-caption p {
    font-size: 1rem;
    width: 100% !important;
    text-align: center;
  }

  .home-banner .carousel-caption .center-btn button {
    font-size: 0.9rem;
  }

  .home-banner .carousel-control {
    margin-top: 3.5rem !important;
    max-width: 35px;
    height: 25px !important;
    width: 25px !important;
    max-height: 35px;
    margin-top: 10%;
  }

  .home-banner .carousel-control i {
    font-size: 1rem;
  }

  .home-banner .carousel-control-left {
    margin-top: 10%;
  }

  .home-banner .carousel-control-left {
    margin-top: 3.5rem;
    margin-left: 0.5rem;
  }

  .home-banner .carousel-control {
    margin-top: 3.5rem;
    margin-right: 0.5rem;
  }

  .home-banner .carousel-indicators {
    position: absolute;
    bottom: 0;
    margin-bottom: 1rem !important;
  }

  .home-banner .carousel-indicators button {
    max-width: 8px;
    height: 8px !important;
    width: 8px !important;
    max-height: 8px;
    margin-right: 10px;
    margin: 1px;
    background-color: transparent !important;
    border: 2px solid #52d1cd !important;
    border-radius: 50%;
    opacity: 0.5;
    transition: all 0.3s;
  }
}

/* Video */
.video-section {
  width: 100%;
  display: flex;
  justify-content: center;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.video-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 991px) {}

@media (max-width: 768px) {
  .video-container {
    top: 0;
    left: 0;
    overflow: hidden;
    padding-bottom: 125%;
  }

  .video-container video {
    width: 100%;
    height: auto;
  }
}

/* home banner 2 slide 2 */
.bg-slide-2 {
  background-image: url("../img/services/background-services.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #bdc6c1;
}

.home-banner-2 {
  /*  min-height: 100vh; */
  display: flex;
  align-items: flex-start;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
  position: relative;
}

.home-banner-2 .carousel {
  width: 100%;
  height: auto;
}

.home-banner-2 .carousel-item {
  min-width: 100%;
  max-height: 100%;
  position: relative;
  overflow: hidden;
  padding: 3rem;
  height: 50rem;
}

.banner-caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 2rem;
}

.home-banner-2 .box-content {
  background-image: url("../img/design/2.png"), url("../img/design/5.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto;
  text-align: center;
  background-color: #ffffff;
  width: 50rem;
  height: 30rem;
  border-radius: 30px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 10px lightslategray;
  z-index: 3;
}

.home-banner-2 .box-content .img-bg {
  width: 50%;
  height: 100%;
  position: relative;
}

.home-banner-2 .box-content .img-bg .img-services {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  left: 0;
}

.home-banner-2 .box-content .img-bg .img-design {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 100;
  left: 40%;
}

.home-banner-2 .box-content .img-bg .img-design-1 {
  display: none;
}

.home-banner-2 .box-content .img-bg .img-design-2 {
  display: none;
}

.home-banner-2 .box-content .img-bg .img-design-4 {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  left: 0;
}

.home-banner-2 .box-content .img-design-6 {
  display: none;
}

.home-banner-2 .box-content .img-design-7 {
  display: none;
}

.home-banner-2 .box-content .img-bg svg {
  position: absolute;
  height: 100%;
  stroke: "none";
  fill: "black";
  transform: rotate(90deg);
  text-align: start;
  z-index: 1;
  left: 10px;
}

.home-banner-2 .box-content .img-bg svg path {
  fill: #ffffff;
  z-index: 10;
}

.home-banner-2 .box-content .description {
  width: 50%;
  height: 100%;
  z-index: 2;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  padding-left: 0rem;
}

.home-banner-2 .box-content .description ul {
  list-style-type: none;
  text-align: left;
  margin-left: -3%;
  z-index: 100;
  /*   position: absolute; */
}

.home-banner-2 .box-content .description ul li {
  font-family: Lato-Light;
  font-size: 2.3rem;
  color: #879282;
  padding-left: 0rem;
  padding-bottom: 1rem;
  font-weight: 900;
  list-style-type: none;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  line-height: 3rem;
}

.home-banner-2 .box-content .description ul li:before {
  content: "";
  background-image: url("../img/design/Rombo.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  width: 1.3rem;
  height: 1.3rem;
  background-size: contain;
  margin-right: 0.2rem;
  margin-bottom: 0rem;
}

.banner-caption h5,
.banner-caption p {
  margin: 0;
}

@media (max-width: 992px) {

  .home-banner-2 .carousel,
  .home-banner-2 img {
    height: auto;
    max-height: none;
  }

  .home-banner-2 .box-content {
    width: 38rem;
    height: 24rem;
  }

  .home-banner-2 .box-content .img-bg svg {
    margin-left: -1rem;
  }

  .home-banner-2 .box-content .description {
    padding-left: 1rem;
  }

  .home-banner-2 .box-content .description ul li:before {
    width: 1rem;
    height: 1rem;
    margin-right: 0.2rem;
    margin-bottom: 0rem;
  }

  .home-banner-2 .box-content .description ul li {
    font-family: Lato-Light;
    font-size: 1.5rem;
    color: #879282;
    line-height: 2rem;
  }
}

@media (max-width: 768px) {
  .home-banner-2 .box-content {
    background-image: none;
  }

  .home-banner-2 .carousel,
  .home-banner-2 img {
    height: auto;
    max-height: none;
  }

  .home-banner-2 .box-content {
    width: 26rem;
    height: 18rem;
  }

  .home-banner-2 .box-content .img-bg {
    width: 80%;
  }

  .home-banner-2 .box-content .img-bg svg {
    display: none;
  }

  .home-banner-2 .box-content .img-bg svg path {
    fill: #ffffff;
  }

  .home-banner-2 .box-content .description {
    width: 100%;
  }

  .home-banner-2 .box-content .description ul {
    list-style-type: none;
    text-align: left;
    margin-left: -15%;
    width: 90%;
  }

  .home-banner-2 .box-content .description ul li {
    font-family: Lato-Light;
    font-size: 1.2rem !important;
    padding-left: 0rem;
    margin-left: 0%;
  }

  .home-banner-2 .box-content .description ul li:before {
    width: 1rem;
    height: 1rem;
    margin-bottom: -0.2rem;
  }

  /* services mobile */
  .home-banner-2 .box-content .img-bg .img-services {
    position: absolute;
    width: 150%;
    height: 100%;
    z-index: 1;
  }

  .home-banner-2 .box-content .img-bg .img-design {
    position: absolute;
    width: 150%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    left: 0%;
  }

  .home-banner-2 .box-content .img-bg .img-design-1 {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    left: 0%;
  }

  .home-banner-2 .box-content .img-design-2 {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0%;
  }

  .home-banner-2 .box-content .img-bg .img-design-4 {
    display: none;
  }

  .home-banner-2 .box-content .img-design-6 {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0%;
  }

  .home-banner-2 .box-content .img-design-7 {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0%;
  }
}

@media (max-width: 576px) {

  .home-banner-2 .carousel,
  .home-banner-2 img {
    height: auto;
    max-height: none;
  }

  .home-banner-2 .box-content {
    width: 100%;
    height: 22rem;
  }

  .home-banner-2 .box-content .img-bg {
    width: 45%;
  }

  .home-banner-2 .box-content .description {
    width: 100%;
    padding-left: 0rem;
    position: absolute;
    margin-left: 35%;
    margin-top: 0%;
  }

  .home-banner-2 .box-content .description ul li {
    font-family: Lato-Light;
    font-size: 1.2rem !important;
    padding-left: 0rem;
    margin-left: 10%;
  }

  .home-banner-2 .box-content .description ul li {
    font-size: 1.5rem;
    line-height: 1.6rem;
  }

  .home-banner-2 .box-content .description ul li:before {
    width: 0.8rem;
    height: 0.8rem;
    margin-bottom: 0rem;
  }
}

.home-banner-2 .carousel-control {
  top: 50%;
  width: 50px;
  height: 50px;
  font-size: 22px;
  background-color: transparent;
  border-radius: 50%;
  transition: all 0.3s;
  right: 0;
  margin-right: 2.5rem;
}

.home-banner-2 .carousel-control-left {
  top: 50%;
  width: 50px;
  height: 50px;
  font-size: 22px;
  background-color: transparent;
  border-radius: 50%;
  transition: all 0.3s;
  right: 0;
  margin-left: 2.5rem;
}

.home-banner-2 .carousel-control:hover {
  background: transparent;
  color: #fff;
  opacity: 1;
}

.home-banner-2 i {
  color: #45807f;
  font-size: 2rem;
  font-weight: 900;
}

.home-banner-2 .carousel-indicators {
  position: absolute;
  bottom: 1rem;
  margin-bottom: 1.5rem !important;
}

.home-banner-2 .carousel-indicators .active {
  opacity: 1;
  background-color: #ffffff !important;
}

.home-banner-2 .carousel-indicators button {
  max-width: 10px;
  height: 8px !important;
  width: 8px !important;
  max-height: 10px;
  margin: 3px;
  margin-right: 10px;
  background-color: transparent !important;
  border: 2px solid #ffffff !important;
  border-radius: 50%;
  opacity: 0.5;
  transition: all 0.3s;
}

.home-banner-2 .arrow-slide {
  width: 1rem;
}

.home-banner-2 .carousel-caption {
  text-align: center !important;
  bottom: 5rem;
}

.home-banner-2 .carousel-caption h5 {
  color: #347674;
  font-size: 3.2rem;
  padding-bottom: 1rem;
  font-family: Lato-Bold;
  text-transform: capitalize;
}

.home-banner-2 .carousel-caption p {
  text-align: left;
  color: #ffffff !important;
  font-family: Lato;
  width: 80%;
  font-size: 1.4rem;
  text-align: center !important;
  margin: 0 auto;
}

.home-banner-2 .carousel-caption .slide2 img {
  max-height: 600px;
  object-fit: contain;
}

.home-banner-2 button {
  background-color: aqua;
  border-radius: 12px;
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.75rem;
  font-size: 0.9rem;
  font-weight: 800;
}

.home-banner-2 .btn-banner {
  background-color: #258ff3;
  color: #ffffff;
}

@media (max-width: 991px) {
  .home-banner-2 .carousel-item {
    height: 43rem;
  }

  .home-banner-2 .carousel-caption {
    text-align: center !important;
    bottom: 12%;
  }

  .home-banner-2 .carousel-caption h5 {
    font-weight: bold;
    font-size: 1.9em;
  }

  .home-banner-2 .carousel-caption p {
    text-align: left;
    font-family: Lato;
    width: 100%;
    font-size: 1em;
    text-align: center;
  }
}

@media (orientation: landscape) and (max-width: 991px) {
  .home-banner-2 .carousel-item {
    height: 43rem;
  }
}

@media (max-width: 768px) {
  .home-banner-2 .carousel-item {
    height: 36rem;
  }

  .home-banner-2 .carousel-indicators {
    bottom: 0.5rem;
  }

  .home-banner-2 .carousel-caption {
    text-align: center !important;
    bottom: 3.2rem;
  }

  .home-banner-2 img {
    width: 25rem;
  }

  .home-banner-2 .carousel-caption h5 {
    font-weight: bold;
    font-size: 1.5em;
  }

  .home-banner-2.carousel-caption p {
    text-align: left;
    color: #a19f9f;
    font-family: Lato;
    width: 70%;
    font-size: 1.4em;
  }

  .home-banner-2 .carousel-control {
    margin-top: 1rem;
    max-width: 35px;
    height: 35px !important;
    width: 35px !important;
    max-height: 35px;
  }

  .home-banner-2 .carousel-control-left {
    margin-top: 1rem;
  }

  .home-banner-2 .carousel-caption h5 {
    font-size: 1.5rem;
    margin-left: 0px;
  }

  .home-banner-2 .carousel-caption p {
    font-size: 1rem;
    margin-left: 0px;
  }

  .home-banner-2 .btn-banner {
    background-color: #258ff3;
    color: #ffffff;
    font-size: 0.6rem;
  }
}

@media (max-width: 576px) {
  .home-banner-2 .carousel-item {
    height: 42rem;
    padding: 2rem;
  }

  .home-banner-2 .carousel-caption {
    text-align: center !important;
    bottom: 5%;
  }

  .home-banner-2 .carousel,
  .home-banner-2 img {
    height: auto;
    max-height: none;
  }

  .home-banner-2 .mobil {
    padding-top: 1rem;
  }

  .home-banner-2 img {
    width: 18rem;
    height: auto;
  }

  .home-banner-2 .carousel-caption h5 {
    font-size: 2em;
  }

  .home-banner-2 .carousel-caption p {
    font-size: 1em;
    width: 100% !important;
    text-align: center;
    margin-left: 0px;
  }

  .home-banner-2 .carousel-control {
    margin-right: 0.5rem;
    margin-top: 25%;
  }

  .home-banner-2 .carousel-control i {
    font-size: 1rem;
  }

  .home-banner-2 .carousel-control-left {
    margin-top: 10%;
  }

  .home-banner-2 .carousel-control-left {
    margin-left: 0.5rem;
    margin-top: 25%;
  }

  .home-banner-2 .carousel-indicators {
    position: absolute;
    bottom: 0;
    margin-bottom: 1rem !important;
  }

  .home-banner-2 .carousel-indicators button {
    max-width: 8px;
    height: 8px !important;
    width: 8px !important;
    max-height: 8px;
    margin-right: 10px;
    margin: 1px;
    background-color: transparent !important;
    border: 2px solid #000000 !important;
    border-radius: 50%;
    opacity: 0.5;
    transition: all 0.3s;
  }
}

/* about us */
.about {
  background-image: url("../img/about/Baner-about-us.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 50rem;
}

.about .description {
  padding-top: 32rem;
}

.about h5 {
  font-family: Lato-Bold;
  color: #347674;
  text-align: center;
  font-size: 3rem;
  font-weight: 100;
  padding-bottom: 0.5rem;
}

.about p {
  color: #606060;
  font-family: Lato;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.3;
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
}

@media (max-width: 992px) {
  .about {
    width: 100%;
    height: 50rem;
  }

  .about .description {
    padding-top: 25rem;
  }

  .about h5 {
    font-size: 3rem;
  }

  .about p {
    font-family: Lato;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.3;
    text-align: center;
    padding-left: 1%;
    padding-right: 1%;
  }
}

@media (max-width: 768px) {
  .about {
    width: 100%;
    height: 45rem;
  }

  .about .blur-about {
    background: rgba(155, 209, 205, 0.3);
    backdrop-filter: blur(5px);
    opacity: 1;
    margin-bottom: 15rem;
    height: 100vh;
  }

  .about .description {
    padding-top: 20rem;
  }

  .about h5 {
    font-size: 2.5rem;
  }

  .about p {
    font-family: Lato;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.3;
    text-align: center;
    padding-left: 1%;
    padding-right: 1%;
  }
}

@media (max-width: 576px) {
  .about {
    width: 100%;
    height: 100vh;
  }

  .about .description {
    padding-top: 10rem;
  }

  .about h5 {
    font-size: 2.5rem;
  }

  .about p {
    color: #606060;
    font-family: Lato;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.3;
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
    text-align: justify;
  }
}

/* CEO */
.ceo {
  width: 100%;
  padding-bottom: 7rem;
}

.ceo .row {
  --bs-gutter-x: 0rem;
  --bs-gutter-y: 0;
  height: 30rem;
}

.ceo .bg-img-ceo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #d2e1de;
}

.ceo .bg-img-ceo .img-ceo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
}

.ceo .bg-img-ceo .img-ceo img {
  width: 20rem;
}

.ceo .bg-img-ceo .name {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ceo .bg-img-ceo .name h5 {
  font-family: Lato-Bold;
  color: #347674;
  font-size: 2rem;
  text-transform: capitalize;
  font-weight: 100;
}

.ceo .bg-img-ceo p {
  font-family: Lato-Light;
  color: #606060;
  font-size: 1rem;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0px;
  font-weight: 400;
}

.ceo .description {
  background-color: #52d1cd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem;
  background-image: url("../img/about/Textura--profile.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.ceo .description .element-img img {
  width: 2rem;
  padding-bottom: 1rem;
}

.ceo .description p {
  font-family: Lato;
  color: #ffffff;
  font-size: 1.2rem;
  text-transform: none;
  text-align: justify;
  letter-spacing: 0px;
  line-height: 1.3;
  font-weight: 100;
}

@media (max-width: 992px) {
  .ceo {
    padding-bottom: 0rem;
  }

  .ceo .row {
    --bs-gutter-x: 0rem;
    --bs-gutter-y: 0;
    height: 100%;
  }

  .ceo .bg-img-ceo {
    height: 30rem;
  }

  .ceo .description {
    height: 38rem;
  }
}

@media (max-width: 768px) {
  .ceo .bg-img-ceo {
    height: 30rem;
  }

  .ceo .description {
    height: 30rem;
  }
}

@media (max-width: 576px) {
  .ceo {}

  .ceo .bg-img-ceo {
    height: 30rem;
  }

  .ceo .description {
    height: 40rem;
  }

  .ceo .description {
    padding: 1rem;
    height: 50rem;
  }

  .ceo .description p {
    padding-left: 10%;
    padding-right: 10%;
    text-align: justify;
  }
}

/* CONTACT  FORM */
.bg-contact {
  background-image: url("../img/design/Textura--Request.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: auto;
}

.contact {}

.contact h5 {
  font-family: Lato-Bold;
  color: #347674;
  font-size: 2rem;
  text-transform: uppercase;
  text-align: center;
  padding-top: 4rem;
}

.contact form {
  padding-top: 3rem;
  padding-bottom: 3rem;
  text-align: center;
}

.contact .btn-send {
  width: 8rem;
  background-color: #9b8c79;
  color: #ffffff;
  border-radius: 15px;
  font-family: Lato-Bold;
}

.contact input {
  font-family: Lato;
  font-size: 1rem;
  height: 3rem;
  padding-top: 1rem;
  color: #606060 !important;
}

.contact input:focus {
  padding-top: 0rem;
}

.contact input::placeholder {
  color: #606060;
}

.contact textarea {
  font-size: Lato;
  font-size: 1rem;
  height: 5rem;
  padding-top: 3rem;
  color: #606060 !important;
}

.contact textarea:focus {
  padding-top: 1rem;
}

.contact textarea::placeholder {
  color: #606060;
}

.contact input:focus {
  color: #000000 !important;
}

.contact textarea:focus {
  color: #000000 !important;
}

@media (max-width: 991px) {
  .contact {
    margin: 0 auto;
  }

  .contact .btn-send {
    width: 8rem;
    border-radius: 15px;
  }
}

@media (max-width: 768px) {
  .contact {
    margin: 0 auto;
  }
}

@media (max-width: 576px) {
  .contact {
    margin: 0 auto;
  }
}

/* banner 2 */
/* .banner2 {
  width: 50rem;
  background-color: rgba(17, 30, 53, 0.8);
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}

.banner2-1-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 25%;
  height: 31rem;
  border-radius: 30px 0px 0px 30px;
}

.banner2 h5 {
  font-family: Lato;
  color: #2da9cc;
  text-align: center;
  font-size: 3rem;
  padding: 5rem 2rem 2rem;
  text-align: start;
}

.banner2 .h5 h5 {
  font-family: Lato;
  color: #2da9cc;
  text-align: center;
  font-size: 3rem;
  padding: 5rem 2rem 0.1rem;
  text-align: start;
}

.banner2 .h5 p {
  padding: 0rem 2rem 2rem;
  color: white;
  font-size: 1rem;
  font-family: Lato;
}

.banner2 ul {
  width: 30rem;
}

.banner2 ul li {
  padding: 1px;
  text-transform: capitalize;
  font-family: Lato;
  text-align: start;
  font-size: 0.9rem;
  color: #eaeceb;
  padding-top: 0.5rem;
}

.banner2 ul li span {
  color: #2da9cc;
  font-weight: 600;
}

.banner2 .diagonal-line {
  position: absolute;
  bottom: 20px;
  right: -8rem;
  width: 15rem;
  height: 4rem;
  background-color: #2da9cc;
  transform: rotate(-45deg);
}

@media (max-width: 991px) {
  .banner2 {
    width: 30rem;
    background-color: rgba(17, 30, 53, 0.8);
    border-radius: 30px;
  }

  .banner2-1-bg {
    width: 25%;
    height: 30rem;
    border-radius: 30px 0px 0px 30px;
  }

  .banner2 h5 {
    width: 30rem;
    font-family: Lato;
    color: #2da9cc;
    text-align: center;
    font-size: 2rem;
    padding: 3.5rem 2rem 2.5rem;
    text-align: start;
  }

  .banner2 .h5 h5 {
    font-size: 2rem;
  }

  .banner2 .h5 p {
    width: 20rem;
  }

  .banner2 ul {
    width: 20rem;
  }

  .banner2 .diagonal-line {
    right: -8rem;
    width: 13rem;
    height: 4rem;
  }
}

@media (max-width: 768px) {
  .banner2 {
    width: 30rem;
    background-color: rgba(17, 30, 53, 0.8);
    border-radius: 30px;
  }

  .banner2-1-bg {
    width: 15%;
    height: 50vh;
    border-radius: 30px 0px 0px 30px;
  }

  .banner2 h5 {
    width: 30rem;
    font-family: Lato;
    color: #2da9cc;
    text-align: center;
    font-size: 2rem;
    padding: 3.5rem 2rem 0.6rem;
    text-align: start;
  }

  .banner2 .h5 h5 {
    padding: 3.5rem 2rem 0.6rem;
    font-size: 2rem;
  }

  .banner2 .h5 p {
    width: 20rem;
  }

  .banner2 ul {
    width: 22rem;
    font-family: Lato;
    padding-top: 0.1rem;
  }

  .banner2 .diagonal-line {
    right: -9rem;
    width: 16rem;
    height: 2.67556rem;
  }
}

@media (max-width: 576px) {
  .banner2 {
    width: 30rem;
    background-color: rgba(17, 30, 53, 0.8);
    border-radius: 30px;
  }

  .banner2-1-bg {
    width: 15%;
    height: 40vh;
    border-radius: 30px 0px 0px 30px;
  }

  .banner2 h5 {
    width: 20rem;
    font-family: Lato;
    color: #2da9cc;
    text-align: center;
    font-size: 1.5rem;
    padding: 1.8rem 2rem 0.2rem;
    text-align: start;
  }

  .banner2 .h5 h5 {
    padding: 3.5rem 2rem 0.2rem;
    font-size: 1.6rem;
  }

  .banner2 .h5 p {
    width: 20rem;
    padding: 0rem 2rem 1rem;
  }

  .banner2 ul {
    width: 100%;
    padding-bottom: 1rem;
  }

  .banner2 ul li {
    font-size: 0.8rem;
  }
}
@media (max-width: 388px) {
  .banner2 .h5 p {
    width: 20rem;
    padding: 0rem 2rem 0.5rem;
    font-size: 0.9rem !important;
  }
}

.modal p {
  font-family: Lato;
  color: black;
  font-size: 1.3rem;
}

.modal button {
  font-family: Lato;
  color: black;
  font-size: 1rem;
} */
/*  Pricacy and Policy */
.privacy-policy header {
  height: 20rem;
  width: 100%;
  background-color: white !important;
}

.privacy-policy header h1 {
  padding-top: 10rem;
  font-family: Lato-Bold;
  color: #347674;
  font-size: 4rem;
}

.privacy-policy .content {
  padding: 1rem;
}

.privacy-policy h2 {
  font-family: Lato;
  padding-bottom: 0.5rem;
}

.privacy-policy p {
  font-family: Lato;
  font-weight: 400;
  line-height: 1.4rem;
  text-align: justify;
  font-size: 1.1rem;
}

.privacy-policy p a {
  color: #000000;
}

@media (max-width: 991px) {
  .privacy-policy header {
    height: 15rem;
  }

  .privacy-policy header h1 {
    padding-top: 6rem;
    font-size: 3.2rem;
  }
}

@media (max-width: 768px) {
  .privacy-policy header {
    height: 15rem;
  }

  .privacy-policy header h1 {
    padding-top: 6rem;
    font-size: 3rem;
  }
}

@media (max-width: 576px) {
  .privacy-policy header {
    height: 17rem;
  }

  .privacy-policy header h1 {
    padding-top: 7rem;
    font-size: 2.5rem;
  }

  .privacy-policy p {
    text-align: justify;
  }
}

/* instagram */
.instagram {
  background-color: #ffffff;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.instagram .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.instagram .title h6 {
  font-family: Lato-Bold;
  text-transform: uppercase;
  color: #606060;
  font-size: 0.9rem;
  padding-top: 2rem;
  padding-bottom: 0.5rem;
}

.instagram .title .icons {
  display: flex;
  padding-bottom: 5rem;
}

.instagram .title .icons a {
  margin-right: 1rem;
  margin-left: 1rem;
  background-color: #9b8c79;
  color: #ffffff;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  font-size: 1.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.instagram .content-box {
  box-shadow: 0px 0px 10px#ccc;
  border-radius: 1rem;
  overflow: hidden;
}

.instagram .card {
  --bs-card-border-width: 0px;
}

.instagram .card .content-box .card-img-top {
  width: 100%;
  height: 13rem;
  object-fit: cover;
  border-top: 1rem;
}

.instagram .card .content-box video {}

.instagram .card h5 {
  font-family: Lato-light;
  font-size: 1rem;
  text-transform: capitalize;
  color: #606060;
}

.instagram .content-box .card-content {
  display: flex;
  justify-content: flex-start;
}

.instagram .card .card-content p {
  font-size: 0.6rem;
  color: #606060;
  font-family: Lato-light;
}

.instagram .gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 4rem;
  padding-bottom: 2rem;
}

.instagram .gallery a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #9b8c79;
  color: #ffffff;
  font-family: Lato-Bold;
  text-transform: capitalize;
  font-size: 1.5rem;
  width: 15rem;
  height: 4rem;
}

.instagram .not-post {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.instagram .not-post h2 {
  font-family: Lato-Bold;
  text-transform: uppercase;
  color: #606060;
  font-size: 1.5rem;
  padding-top: 2rem;
  padding-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .instagram .card .content-box .card-img-top {
    width: 100%;
    height: 20rem;
  }
}

.not-found {
  padding-top: 15rem;
  background-color: #ffffff;
  padding-bottom: 10rem;
  text-align: center;
}

.not-found .header h1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  color: #347674;
  font-family: Lato-Bold;
  font-size: 3rem;
  display: flex;
}